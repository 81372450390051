<template>
  <div class="companies">
    <div class="companies__buttons">
      <button class="companies__btn" @click="openModal">
        Добавить компанию
      </button>
    </div>
    <div class="companies__wrapper">
      <h1 class="companies__title">Все компании</h1>
      <div class="companies__search">
        <input
          class="search"
          type="text"
          placeholder="Поиск"
          v-model="searchCompanies"
          @input="searchInput"
        />
        <div class="companies__search-icon">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.24235 6C1.24235 3.37242 3.37242 1.24235 6 1.24235C8.62758 1.24235 10.7577 3.37242 10.7577 6C10.7577 8.62758 8.62758 10.7577 6 10.7577C3.37242 10.7577 1.24235 8.62758 1.24235 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C7.43148 12 8.74588 11.4987 9.77721 10.6621L12.9319 13.8167C13.1762 14.0611 13.5724 14.0611 13.8167 13.8167C14.0611 13.5724 14.0611 13.1762 13.8167 12.9319L10.6621 9.77721C11.4987 8.74588 12 7.43148 12 6C12 2.68629 9.31371 0 6 0Z"
              fill="#AEB3CB"
            />
          </svg>
        </div>
      </div>
      <div class="companies__list">
        <div
          class="companies__list-companies"
          v-if="$store.state.companies.isVisibleCompanies"
        >
          <div
            class="companies__list-table"
            v-if="$store.state.companies.allCompanies.length"
          >
            <AllCompanies />
          </div>

          <div
            class="companies__list-spinner"
            v-else-if="$store.state.companies.spinnerIsShow"
          >
            <SpinnerComponent />
          </div>
          <div class="companies__list-noCompanies" v-else>
            Компании отсутствуют
          </div>
        </div>
      </div>
      <div
        class="companies__createCompany"
        v-if="$store.state.companies.isAddedCompany"
      >
        <ModalCreateCompany />
      </div>
    </div>
  </div>
</template>

<script>
import AllCompanies from "@/components/adminPageComponents/AllCompanies.vue";
import ModalCreateCompany from "@/components/modal/ModalCreateCompany.vue";
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "companiesMain",
  components: {
    AllCompanies,
    SpinnerComponent,
    ModalCreateCompany,
  },
  setup() {
    const store = useStore();

    const searchCompanies = ref("");
    const searchInput = () => {
      if (searchCompanies.value.length)
        store.commit("searchCompany", searchCompanies.value);
      else store.commit("searchCompanyUpdate");
    };
    const openModal = () => {
      store.commit("setAddedCompany", true);
      // сброс поиска
      store.commit("searchCompanyUpdate");
    };
    onMounted(() => {
      if (!store.state.companies.allCompanies.length) {
        store.dispatch("getAllCompanies").then(() => {
          if (!store.state.companies.allCompanies.length) {
            store.commit("setVisibleCompanies", false);
          }
        });
      } else {
        store.commit("setVisibleCompanies", true);
      }
      // Обновляем активную компанию
      store.commit("setCompanyForGroup", null);
    });

    return {
      searchCompanies,
      searchInput,
      openModal,
    };
  },
};
</script>
<style></style>
