import analitics from "@/store/analitics";
import billing from "@/store/billing.js";
import companies from "@/store/companies.js";
import graphSchemes from "@/store/graphSchemes.js";
import mainData from "@/store/mainData.js";
import reports from "@/store/reports.js";
import schemes from "@/store/schemes.js";
import users from "@/store/users.js";
import { createStore } from "vuex";
export default createStore({
  modules: {
    mainData,
    schemes,
    companies,
    users,
    billing,
    graphSchemes,
    reports,
    analitics,
  },
});
