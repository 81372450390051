import AdminPanelPage from "@/pages/AdminPanelPage.vue";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/admin/:activeItem?",
    name: "adminPanelPage",
    component: AdminPanelPage,
  },
  {
    path: "/",
    redirect: "/schemes",
  },
  {
    path: "/:notFound(.*)",
    redirect: "/schemes",
  },
  {
    path: "/login",
    name: "loginPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/pages/AutorizationPage.vue"),
    meta: {
      layout: "AuthLayout",
    },
  },
  {
    path: `/schemes/:id?`,
    name: "schema",
    component: () => import("@/pages/MainPage.vue"),
    meta: {
      layout: "DefaultLayout",
    },
  },
  {
    path: `/analytics/:id?`,
    name: "analytics",
    component: () => import("@/pages/AnalyticsPage.vue"),
    meta: {
      layout: "DefaultLayout",
    },
  },
  {
    path: `/analytics/:id/anpr-report`,
    name: "anprReport",
    component: () =>
      import("@/components/AnalyticsPageComponents/ReportPage.vue"),
    meta: {
      layout: "DefaultLayout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
