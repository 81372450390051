<template>
  <div class="admin">
    <div class="admin__sidebar">
      <ul class="sidebar">
        <li
          class="sidebar__item"
          :class="{ 'item-active': activeSidebarItem == 'users' }"
          @click="setSidebarItem('users')"
        >
          <div class="sidebar__item-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-people"
              viewBox="0 0 16 16"
            >
              <path
                d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
              />
            </svg>
          </div>
          <div class="sidebar__item-text">Пользователи</div>
        </li>
        <li
          class="sidebar__item"
          :class="{ 'item-active': activeSidebarItem == 'companies' }"
          @click="setSidebarItem('companies')"
        >
          <div class="sidebar__item-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-journal-medical"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a.5.5 0 0 1 .5.5v.634l.549-.317a.5.5 0 1 1 .5.866L9 6l.549.317a.5.5 0 1 1-.5.866L8.5 6.866V7.5a.5.5 0 0 1-1 0v-.634l-.549.317a.5.5 0 1 1-.5-.866L7 6l-.549-.317a.5.5 0 0 1 .5-.866l.549.317V4.5A.5.5 0 0 1 8 4zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"
              />
              <path
                d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z"
              />
              <path
                d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z"
              />
            </svg>
          </div>
          <div class="sidebar__item-text">Все компании</div>
        </li>
        <li
          class="sidebar__item"
          :class="{ 'item-active': activeSidebarItem == 'billing' }"
          @click="setSidebarItem('billing')"
        >
          <div class="sidebar__item-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-cash-stack"
              viewBox="0 0 16 16"
            >
              <path
                d="M1 3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1H1zm7 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
              />
              <path
                d="M0 5a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5zm3 0a2 2 0 0 1-2 2v4a2 2 0 0 1 2 2h10a2 2 0 0 1 2-2V7a2 2 0 0 1-2-2H3z"
              />
            </svg>
          </div>
          <div class="sidebar__item-text">Система биллинга</div>
        </li>
        <li
          class="sidebar__item"
          :class="{ 'item-active': activeSidebarItem == 'reports' }"
          @click="setSidebarItem('reports')"
        >
          <div class="sidebar__item-logo">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-table"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z"
              />
            </svg>
          </div>
          <div class="sidebar__item-text">Отчёты</div>
        </li>
      </ul>
    </div>
    <div class="admin__main">
      <UsersMain v-if="activeSidebarItem == 'users'" />
      <CompaniesMain v-if="activeSidebarItem == 'companies'" />
      <BillingMain v-if="activeSidebarItem == 'billing'" />
      <ReportsMain v-if="activeSidebarItem == 'reports'" />
    </div>
  </div>
</template>

<script>
import BillingMain from "@/components/adminPageComponents/BillingMain.vue";
import CompaniesMain from "@/components/adminPageComponents/CompaniesMain.vue";
import ReportsMain from "@/components/adminPageComponents/ReportsMain.vue";
import UsersMain from "@/components/adminPageComponents/UsersMain.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "adminPanelPage",
  components: {
    UsersMain,
    CompaniesMain,
    BillingMain,
    ReportsMain,
  },
  setup() {
    const activeSidebarItem = ref("users");
    const route = useRoute();
    const setSidebarItem = value => {
      activeSidebarItem.value = value;
    };
    onMounted(() => {
      if (route.params.activeItem)
        activeSidebarItem.value = route.params.activeItem;
    });
    return {
      activeSidebarItem,
      setSidebarItem,
    };
  },
};
</script>
<style lang="scss" src="@/assets/styles/adminPage.scss"></style>
