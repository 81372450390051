<template>
  <div class="reports">
    <GrayCover v-if="teleportCenter && dpIsOpened" @click.stop />
    <div class="reports__inner">
      <h1 class="reports__title">Отчёт по состоянию объектов</h1>
      <div class="reports__menu">
        <div class="reports__companies">
          <div class="reports__text">Компании</div>
          <div class="reports__companiesAll">
            <input
              class="reports__checkbox"
              type="checkbox"
              ref="checkboxAll"
              id="checkbox__allCompanies"
              :checked="getAllCheckboxesIsChecked"
              @click="selectAllCheckbox"
            />
            <label class="reports__label" for="checkbox__allCompanies"
              >Выделить все</label
            >
          </div>
          <div
            class="reports__company"
            v-for="company in allCompanies"
            :key="company.id"
          >
            <input
              class="reports__checkbox"
              type="checkbox"
              :id="'checkbox__company' + company.id"
              :checked="company.checked"
              @click="selectCheckbox(company)"
            />
            <label
              class="reports__label"
              :for="'checkbox__company' + company.id"
              >{{ company.name }}</label
            >
          </div>
        </div>
        <div class="reports__dates">
          <div class="reports__text">Временной интервал</div>
          <div class="reports__ddownMenu">
            <select
              class="reports__select"
              id="reports__select"
              :value="selectInput"
              @change="changeSelect"
              :disabled="false"
            >
              <option
                v-for="option in optionsTimeInterval"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <div class="reports__text">С</div>
          <div class="reports__dateA">
            <VueDatePicker
              @open="openDatePicker"
              @closed="closedDatePicker"
              selectText="Выбрать"
              cancelText="Отмена"
              class="datePicker-one"
              placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
              v-model="dateA"
              lang="ru"
              autocomplete="off"
              :locale="'ru'"
              format="dd.MM.yyyy HH:mm"
              :time-picker-component="timePicker"
              :teleport-center="teleportCenter"
              textInput
              monthNameFormat="long"
            />
          </div>
          <div class="reports__text">ДО</div>
          <div class="reports__dateB">
            <VueDatePicker
              @open="openDatePicker"
              @closed="closedDatePicker"
              selectText="Выбрать"
              cancelText="Отмена"
              class="datePicker-two"
              placeholder="ДД.ММ.ГГГГ ЧЧ:ММ"
              v-model="dateB"
              lang="ru"
              autocomplete="off"
              :locale="'ru'"
              format="dd.MM.yyyy HH:mm"
              :time-picker-component="timePicker"
              :teleport-center="teleportCenter"
              textInput
              monthNameFormat="long"
            />
          </div>
          <div class="reports__btngroup">
            <div class="reports__btn">
              <button @click="generateReport">Сформировать отчет</button>
            </div>
            <div class="reports__btn">
              <button @click="makeXLS()" :disabled="!allReports">
                Выгрузка отчёта
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="reports__table" v-if="allReports">
        <div class="allReports">
          <div class="allReports__head">
            <div class="allReports__head-date">Дата</div>
            <div class="allReports__head-group">Группа</div>
            <div class="allReports__head-desc">Описание</div>
          </div>
          <div class="allReports__companies">
            <div
              class="allReports__company"
              v-for="report in allReports"
              :key="report.id"
            >
              <div class="allReports__company-name">{{ report.name }}</div>
              <div class="allReports__empty" v-if="!report.statusEvents.length">
                Данных нет
              </div>
              <div
                class="allReports__object"
                v-else
                v-for="(statusEvent, statEvtIdx) in report.statusEvents"
                :key="statusEvent.id"
              >
                <div
                  class="allReports__general"
                  :style="{
                    'background-color':
                      statusEvent.status == 'ONLINE'
                        ? 'rgb(154, 255, 154)'
                        : 'rgb(255, 174, 185)',
                  }"
                  @click.stop="
                    $store.commit('setReportGroupVisibility', {
                      reportId: report.id,
                      statusEvtIdx: statEvtIdx,
                    })
                  "
                >
                  <div class="allReports__general-col1">
                    <div class="allReports__general-arr">
                      <svg
                        :style="{
                          transform: !statusEvent.visibleGroup
                            ? 'rotate(-90deg)'
                            : 'rotate(0)',
                        }"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 1000 1000"
                        enable-background="new 0 0 1000 1000"
                        xml:space="preserve"
                      >
                        <g>
                          <path
                            d="M500,767.1c-6.2,0-11.4-2.1-15.5-7.2L36.2,267.1c-5.2-6.2-7.3-14.5-4.1-22.8c3.1-7.2,10.4-12.4,18.6-12.4h896.5c8.3,0,15.5,5.2,18.6,12.4c3.1,7.2,2.1,16.6-4.1,22.8L513.5,759.8C511.4,764,506.2,767.1,500,767.1z"
                          />
                          <path
                            d="M948.3,253.6L500,746.4L51.7,253.6H948.3 M948.3,212.2H51.7c-16.6,0-31.1,9.3-38.3,24.8c-6.2,14.5-4.1,32.1,7.2,44.5l448.3,492.8c8.3,8.3,18.6,13.5,31.1,13.5c12.4,0,22.8-5.2,31.1-13.5l448.3-492.8c11.4-12.4,13.5-30,7.2-44.5C979.3,222.6,964.8,212.2,948.3,212.2L948.3,212.2z"
                          />
                        </g>
                      </svg>
                    </div>
                    <div class="allReports__general-date">
                      {{ new Date(statusEvent.time).toLocaleString("ru-Ru") }}
                    </div>
                  </div>
                  <div class="allReports__general-group">
                    {{ statusEvent.name }}
                  </div>
                  <div class="allReports__general-desc">
                    {{ statusEvent.trackObjects.length }} объектов
                    {{
                      statusEvent.status == "ONLINE"
                        ? "вышли на связь"
                        : "перестали отвечать"
                    }}
                  </div>
                </div>
                <div class="allReports__body" v-if="statusEvent.visibleGroup">
                  <table class="allReports__table">
                    <thead class="allReports__thead">
                      <tr>
                        <th class="allReports__thead-num">id</th>
                        <th class="allReports__thead-name">Наименование</th>
                        <th class="allReports__thead-ip">Ip</th>
                        <th class="allReports__thead-status">Статус</th>
                      </tr>
                    </thead>
                    <tbody class="allReports__tbody">
                      <tr
                        v-for="trackingObject in statusEvent.trackObjects"
                        :key="trackingObject.id"
                      >
                        <td class="allReports__tbody-num">
                          {{ trackingObject.id }}
                        </td>
                        <td class="allReports__tbody-name">
                          {{ trackingObject.name }}
                        </td>
                        <td class="allReports__tbody-ip">
                          {{ trackingObject.ip }}
                        </td>
                        <td class="allReports__tbody-status">
                          {{ trackingObject.isOnline ? "ONLINE" : "OFFLINE" }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/styles/uiStyles/datePicker.scss";
import GrayCover from "@/components/UI/coversForModals/GrayCoverVue.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import xlsx from "json-as-xlsx";
import { computed, defineAsyncComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
const TimePicker = defineAsyncComponent(() =>
  import("@/components/UI/TimePickerCustom.vue"),
);
export default {
  name: "reportsMain",
  components: { VueDatePicker, GrayCover },
  setup() {
    const store = useStore();
    const teleportCenter = computed(
      () => window.innerWidth < 480 || window.innerHeight < 480,
    ); // если просмотр на мобильных телефонах datepicker делаем посередине экрана
    const timePicker = computed(() => TimePicker);
    const allCompanies = computed(() => store.state.companies.allCompanies); // получение списка всех компаний
    const allReports = computed(() => store.state.reports.reportsData); // получение списка всех событий
    const checkboxAll = ref(false); // состояние чекбокса "Выбрать все"
    const dpIsOpened = ref(false); // состояние датапикера
    // computed cв-во которое убирает/ добавляет галочку "Выбрать все"
    const getAllCheckboxesIsChecked = computed(
      () =>
        !store.state.companies.allCompanies.find(company => !company.checked),
    );
    // computed cв-во возвращает массив ids выбранных чекбоксов
    const getCheckedCheckboxesIds = computed(() =>
      store.state.companies.allCompanies
        .filter(company => company.checked)
        .map(item => item.id),
    );

    // состояние SELECT
    const selectInput = ref(0); // выбранный тип дня
    const optionsTimeInterval = ref([
      { id: 0, name: "Сегодня" },
      { id: 1, name: "Вчера" },
      { id: 2, name: "Текущая неделя" },
      { id: 3, name: "Предыдущая неделя" },
      { id: 4, name: "Текущий месяц" },
      { id: 5, name: "Предыдущий месяц" },
      { id: 6, name: "На выбор" },
    ]);
    const dateA = ref(new Date(new Date(new Date()).setHours(0, 0, 0, 0)));
    const dateB = ref(new Date(new Date(new Date()).setHours(23, 59, 0, 0)));
    const selectCheckbox = company => {
      // выбор и снятие одного чекбокса
      store.commit("setToggleCheckbox", {
        id: company.id,
        isChecked: !company.checked,
      });
    };
    const selectAllCheckbox = () => {
      // выбор и снятие всех чекбоксов
      store.commit(
        "setSelectAllCheckboxes",
        checkboxAll.value.checked ? true : false,
      );
    };
    function startDay(day = new Date()) {
      return new Date(new Date(day).setHours(0, 0, 0, 0));
    }
    function endDay(day = new Date()) {
      return new Date(new Date(day).setHours(23, 59, 0, 0));
    }
    // получение временного отрезка
    const changeSelect = event => {
      const currentDayWeek = new Date().getDate();
      const previousSunday = currentDayWeek - new Date().getDay();
      const firstDayMonth = new Date().getDate() - (new Date().getDate() - 1);
      const lastDayMounth =
        new Date().getDate() - (new Date().getDate() - 1) - 1;
      const thisMounth = new Date().getMonth();
      const previousMonth = new Date().getMonth() - 1;

      selectInput.value = event.target.value;
      // срабатывает эта ф-ция => после запускается watch(что ниже)
      if (selectInput.value == 0) {
        dateA.value = startDay();
        dateB.value = endDay();
      } else if (selectInput.value == 1) {
        dateA.value = startDay(new Date().setDate(currentDayWeek - 1));
        dateB.value = endDay(new Date().setDate(currentDayWeek - 1));
      } else if (selectInput.value == 2) {
        dateA.value = startDay(new Date().setDate(previousSunday + 1));
        dateB.value = endDay(new Date().setDate(currentDayWeek));
      } else if (selectInput.value == 3) {
        dateA.value = startDay(new Date().setDate(previousSunday - 6));
        dateB.value = endDay(new Date().setDate(previousSunday));
      } else if (selectInput.value == 4) {
        dateA.value = startDay(new Date().setDate(firstDayMonth));
        dateB.value = endDay(new Date().setDate(new Date().getDate()));
      } else if (selectInput.value == 5) {
        dateA.value = startDay(
          new Date(new Date().setMonth(previousMonth)).setDate(firstDayMonth),
        );
        dateB.value = endDay(
          new Date(new Date().setMonth(thisMounth)).setDate(lastDayMounth),
        );
      } else {
        dateA.value = null;
        dateB.value = null;
      }
      // нужно для дальнейшего сравнения НЕСООТВЕТСТВИЯ диапазона даты и введенного вручную другую дату
      changeTimeRangeA.value = dateA.value;
      changeTimeRangeB.value = dateB.value;
    };

    const changeTimeRangeA = ref(null);
    const changeTimeRangeB = ref(null);
    // логика работы дат в селекте 'На выбор' ( автомат. отрисовывает актуальное время  )
    const openDatePicker = () => {
      dpIsOpened.value = true;
      if (selectInput.value == 6 && dateA.value == null) {
        dateA.value = new Date();
        changeTimeRangeA.value = dateA.value;
      }
      if (selectInput.value == 6 && dateB.value == null) {
        dateB.value = new Date();
        changeTimeRangeB.value = dateB.value;
      }
    };
    // сравниваем выбранный диапазон дат с изменнением полей(при вводе в ручную)
    const closedDatePicker = () => {
      dpIsOpened.value = false;
      if (
        dateA.value != changeTimeRangeA.value ||
        dateB.value != changeTimeRangeB.value
      ) {
        selectInput.value = 6;
      }
    };
    // cформировать отчет
    const generateReport = () => {
      if (!getCheckedCheckboxesIds.value.length) {
        store.dispatch("setTimeoutAlert", {
          text: "Выберите хотя бы одну компанию",
          type: "warning",
        });
        return;
      }
      const data = {
        from: dateA.value.toISOString(),
        to: dateB.value.toISOString(),
        ids: getCheckedCheckboxesIds.value,
      };
      store.dispatch("getReports", data);
    };
    const makeXLS = () => {
      const data = [];
      allReports.value.forEach(report => {
        const reportName = report.name.replace(/[[\]]/g, "");
        const dataItm = {
          sheet:
            reportName.length > 30
              ? reportName.slice(0, 27).concat("...")
              : reportName, // название вкладки
          columns: [
            { label: "Дата", value: "date" },
            { label: "Имя", value: "name" },
            { label: "Ip", value: "ip" },
            { label: "Тип", value: "type" },
            { label: "Статус", value: "status" },
          ],
          content: [],
        };
        report.statusEvents.forEach(statusEvt => {
          statusEvt.trackObjects.forEach(tObj => {
            dataItm.content.push({
              date: new Date(statusEvt.time).toLocaleString("ru-RU"),
              name: tObj.name,
              ip: tObj.ip,
              type: tObj.type,
              status: tObj.isOnline ? "ONLINE" : "OFFLINE",
            });
          });
        });
        data.push(dataItm);
      });
      // объект с таблицами  для печать
      const docDefinition = data;
      const settings = { fileName: data[0].sheet };

      // export в excel
      xlsx(docDefinition, settings);
    };
    onMounted(() => {
      // запрет на появление стандартной андроид клавиатуры
      const inputDP1 = document.querySelector(".reports__dateA .dp__input");
      const inputDP2 = document.querySelector(".reports__dateB .dp__input");
      inputDP1.addEventListener("click", () => {
        inputDP1.blur();
      });
      inputDP1.addEventListener("focus", () => {
        inputDP1.blur();
      });
      inputDP2.addEventListener("click", () => {
        inputDP2.blur();
      });
      inputDP2.addEventListener("focus", () => {
        inputDP2.blur();
      });
    });
    return {
      allCompanies,
      allReports,
      checkboxAll,
      getAllCheckboxesIsChecked,
      selectInput,
      optionsTimeInterval,
      dateA,
      dateB,
      timePicker,
      dpIsOpened,
      teleportCenter,
      selectCheckbox,
      selectAllCheckbox,
      changeSelect,
      openDatePicker,
      closedDatePicker,
      generateReport,
      makeXLS,
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .dp__input_icon_pad {
  padding-left: 40px !important;
}
.reports {
  width: 100%;
  height: calc(100vh - 90px);
  height: calc(100dvh - 90px);
  overflow: hidden;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  &__inner {
    padding: 25px;
    height: 100%;
    background-color: #fff;
    overflow: auto;
  }
  &__title {
    font-style: normal;
    line-height: 1.5;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }
  &__checkbox {
    appearance: none;
    width: 1.3rem;
    height: 1.3rem;
    border: 1px solid #00000040;
    border-radius: 0.25em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    &:checked {
      background-color: #e31e24;
    }
  }
  &__select {
    width: 100%;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.33rem 0.75rem;
    padding-right: 20px;
    margin-top: 1.2rem;
    outline: none;
    &.focus {
      border-color: #e31e24;
      box-shadow: inset 0px 0px 10px rgb(227 30 36 / 25%);
    }
    &:disabled {
      cursor: not-allowed;
      color: #ced4da;
    }
  }
  &__text {
    font-style: normal;
    line-height: 1;
    color: #9c9c9c;
    font-weight: 700;
    font-size: 1rem;
    margin-top: 10px;
  }
  &__menu {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 10px;
  }
  &__companies,
  &__dates {
    width: 50%;
  }
  &__dates {
    padding-top: 10px;
  }
  &__companiesAll,
  &__company {
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 1rem;
  }
  &__companiesAll {
    position: relative;
    margin-bottom: 10px;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 70%;
      height: 1px;
      background-color: red;
    }
  }
  &__btngroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 10px;
    margin-top: 1rem;
  }
  &__btn {
    button {
      color: #fff;
      background-color: #e31e24;
      border-color: #e31e24;
      border: 1px solid transparent;
      padding: 9px 12px;
      text-align: center;
      border-radius: 0.25rem;
      cursor: pointer;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      &:hover {
        color: #fff;
        background-color: #b02a37;
        border-color: #b02a37;
      }
      &:disabled {
        cursor: not-allowed;
        background-color: #f2f2f2;
        border-color: #f2f2f2;
      }
    }
  }
  &__table {
    margin-top: 20px;
  }
}
.allReports {
  width: 70%;
  margin: 0 auto;
  &__empty {
    border: 1px solid #dee2e6;
    text-align: center;
    padding: 0.5rem;
  }
  &__head,
  &__general {
    display: flex;
    border: 1px solid #dee2e6;
    text-align: center;
    padding: 0.5rem;
    &-col1 {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 5px;
    }
    &-arr {
      width: 10px;
      height: 10px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &-group {
      width: 20%;
    }
    &-desc {
      width: 60%;
    }
  }
  &__head-date {
    width: 20%;
  }
  &__head {
    font-weight: 700;
  }
  &__general {
    border: 0;
    cursor: pointer;
    &-arr {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15px;
      height: 15px;
      & > svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  &__company {
    &-name {
      padding: 0.5rem;
      text-align: center;
      border-left: 1px solid #dee2e6;
      border-right: 1px solid #dee2e6;
      background-color: #f2f2f2;
    }
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
  }
  &__thead {
    &-num {
      width: 50px;
    }
  }
  tr {
    text-align: center;
    position: relative;
    background-color: #f2f2f2;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 97%;
      height: 1px;
      background-color: #dee2e6;
    }
  }
  td,
  th {
    padding: 0.5rem 0;
  }
}
@media (max-width: 1135px) {
  .allReports {
    &__general {
      &-arr {
        width: 10px;
        height: 10px;
      }
    }
  }
}
@media (max-width: 768px) {
  .reports {
    &__btn {
      button {
        padding: 8px;
      }
    }
  }
  .allReports {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .reports {
    &__menu {
      flex-wrap: wrap;
    }
    &__companies,
    &__dates {
      width: 100%;
    }
  }
}
@media (max-width: 480px) {
  .reports {
    &__inner {
      padding: 10px;
    }
    &__title {
      margin-bottom: 0;
    }
    &__companiesAll {
      margin-top: 5px;
    }
    &__checkbox {
      width: 2rem;
      height: 2rem;
    }
    &__select {
      padding: 0.75rem;
    }
  }
  .allReports {
    &__general {
      padding: 1rem 0.5rem;
      &-desc {
        width: 55%;
      }
      &-arr {
        flex-shrink: 0;
        width: 7px;
        height: 7px;
        svg {
          flex-shrink: 0;
        }
      }
    }
  }
}
</style>
