<template>
  <v-app>
    <v-main>
      <component :is="layout">
        <router-view :key="$route.fullPath" />
      </component>
      <Alert />
    </v-main>
  </v-app>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";
import Alert from "./components/UI/AlertComponent.vue";
export default {
  components: {
    DefaultLayout,
    AuthLayout,
    Alert,
  },
  setup() {
    document.title = "Камеры | Альянс-ЮГ";
    const route = useRoute();
    const layout = computed(() => {
      return route.meta.layout || DefaultLayout;
    });
    return { layout };
  },
};
</script>
